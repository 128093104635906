<template>
  <div class="content">
    <div class="bgtop">
      <div class="topLeft">
        <img class="goUp"
             src="@/assets/interesing/退出@2x.png"
             @click="back"
             alt="">
        <span>答案详情</span>
      </div>
    </div>
    <div class="moredetail">
      <div class="left-section">
        <div class="upper-box">
          <el-progress type="circle"
                       :width="200"
                       :stroke-width="16"
                       :percentage="(this.rankListData[0].exceed*100 ).toFixed(1)"></el-progress>
          <div class="text">
            你超越了{{(this.rankListData[0].exceed*100 ).toFixed(1)}}%的同学，继续加油！
          </div>
        </div>
        <div class="lower-box">
          <div class="shuju">
            累计数据
          </div>
          <div class="accuracyrate">
            本周正确率
            <el-progress :percentage="this.rankListData[0].accuracy"></el-progress>
          </div>
          <div class="ranking">
            本周排名
            <el-progress :percentage="this.rankListData[0].rank"
                         :format="format"></el-progress>
          </div>
        </div>
      </div>
      <div class="right-section">
        <div class="answerbox">
          <div class="answer">答题情况
          </div>
          <div class="zhengque">正确率{{this.detailList.rank_info.accuracy *100}}%,用时{{this.detailList.use_time}}秒</div>
        </div>
        <div class="checktopic"
             v-if="!correct">
          <div class='zc'
               :style="{ backgroundColor: item.isTrue == false ? 'rgba(242,65,58,0.1)' : item.isTrue == true ? 'rgba(84, 191, 38, 0.1)' : '#fafafa'}"
               v-for="(item, index) in topicListData"
               :key="index">
            <div class="content"
                 :style="{ color: item.isTrue == false ? '#F2413A' : item.isTrue == true ? '#54BF26' : '#fafafa'}">
              {{ item.choiceResult ? item.choiceResult : item.answer_id}}
            </div>
          </div>
        </div>
        <div class="checktopic"
             v-if="correct">
          <div :class="[item.isTrue  ? 'checkAnswer' : 'checkAnswer']"
               v-for="(item, index) in topicListData"
               :key="index">
            <div class="content"
                 v-if="item.question_answer !== item.choiceResult">
              正确答案:
            </div>
            <div class="content">
              {{ item.question_answer }}
            </div>
          </div>
        </div>
        <div class="Comments">
          <div class="shade">老师点评</div>
          <div class="context">
            {{this.detailList.comment}}
          </div>

        </div>
        <div class="annv">
          <!-- <div class="parse"
               @click="showAnswer">
            查看错题解析
          </div> -->
          <div class="training"
               @click="toAnwser">继续训练</div>
        </div>
      </div>
    </div>
    <div>

    </div>
  </div>
</template>
<script>
import { getRank, getDetail } from '@/api/Aaswer.js'
export default {
  data () {
    return {
      topicListData: [],
      rightNum: 0,
      errorNum: 0,
      accuracyData: 0,
      rankListData: [],
      detailList: {},
      exceed: '',
      correct: false
    }
  },
  async created () {
    this.topicListData = this.$route.query.topicListData
    console.log('sssssssss', this.topicListData)
    this.rightNum = this.$route.query.rightNum
    this.errorNum = this.$route.query.errorNum
    let userinfo = JSON.parse(window.localStorage.getItem('userInfo'))
    let userId = Number(userinfo.user_id)
    let subjectId = Number(this.$route.query.subjectId)
    const rankList = await getRank({
      uid: userId,
      subject_id: subjectId
    })
    this.rankListData = rankList.data.filter(item => item.subject_id == this.$route.query.subjectId)
    // console.log('this.rankListData',this.rankListData)
    const { data } = await getDetail({
      uid: userId,
      group_id: this.$route.query.group_id
    })
    this.detailList = data
    console.log('s', this.detailList.question_list)
  },
  mounted () {
    console.log('oo', this.$router) // 访问router实例，确保已注入该依赖
  },
  methods: {
    toIndex (item, index) {
      console.log('item', item)
      console.log('index', index)

      this.$router.push({
        path: '/Answer',
        query: {
          subject_id: item.subject_id,
          answer_id: item.answer_id,
          index: index
        }
      })


    },
    format (percentage) {

      return percentage ? `${percentage}` : `${percentage}`;
    },
    showAnswer () {
      this.correct = true
    },
    back () {
      this.$router.push('/interesting')
    },
    toAnwser () {
      console.log('520', this.$route.query.subjectId)
      this.$router.push({
        path: '/Answer',
        query: {
          subject_id: this.$route.query.subjectId,
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.content {
  cursor: pointer;
  font-size: 28rem;
  .bgtop {
    width: 100%;
    height: 100rem;
    padding: 26rem 40rem;
    box-sizing: border-box;
    background: #2196f3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .topLeft {
      display: flex;
      justify-content: center;
      align-items: center;
      .goUp {
        width: 48rem;
        height: 48rem;
        margin-right: 24rem;
      }
      span {
        font-size: 28rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 42rem;
      }
    }
  }
  .moredetail {
    display: grid;
    grid-template-columns: 430rem 1fr; /* 左右两列等宽 */
    grid-gap: 16rem; /* 网格间距 */
    height: 965rem;
    background-color: #f5f5f5;
    padding: 40rem;
    box-sizing: border-box;
    .left-section {
      display: flex;
      flex-direction: column;
      .upper-box {
        height: 50%;
        margin-bottom: 10rem;
        border-radius: 24rem;
        background-color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        .text {
          font-size: 20px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
      }
      .lower-box {
        height: 50%;
        border-radius: 24rem;
        background-color: #ffffff;
        padding: 32rem;
        box-sizing: border-box;
        .shuju {
          font-size: 28rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        .accuracyrate {
          margin-top: 40rem;
          margin-bottom: 24rem;
          font-size: 24rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
        .ranking {
          margin-top: 40rem;
          margin-bottom: 24rem;
          font-size: 24rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
      }
    }
    .right-section {
      width: 100%;
      border-radius: 24rem;
      background-color: #ffffff;
      padding: 32rem;
      box-sizing: border-box;
      .answerbox {
        display: flex;
        align-items: center;
        margin-bottom: 24rem;
        .answer {
          font-size: 28rem;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        .zhengque {
          font-size: 24rem;
          margin-left: 16rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
      }
      .checktopic {
        width: 820rem;
        height: 280rem;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 10rem;
        // justify-items: center; // 居中对齐每个单元格的内容
        align-items: center;
        margin-bottom: 64rem;

        .checkbox,
        .checkAnswer {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 128rem;
          height: 128rem;
          background: rgba(84, 191, 38, 0.1);
          border-radius: 16rem;
        }
        .error {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 128rem;
          height: 128rem;
          background: #f2413a;
          border-radius: 16rem;
        }
        .zc {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 128rem;
          height: 128rem;
          // background: #fafafa;
          border-radius: 16rem;
        }
      }
      .Comments {
        width: 1306rem;
        height: 100rem;
        line-height: 36rem;
        box-sizing: border-box;
        background: #fafafa;
        border-radius: 16rem;
        font-size: 24rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        margin-bottom: 64rem;
        position: relative;
        .shade {
          position: absolute;
          font-size: 56rem;
          font-family: YouSheBiaoTiHei-Regular, YouSheBiaoTiHei;
          font-weight: 600;
          line-height: 64rem;
          top: 0;
          left: 16rem;
          background-image: linear-gradient(
            to bottom,
            rgb(206, 206, 214),
            rgba(196, 187, 187, 0)
          ); /* 添加渐变背景色 */
          -webkit-background-clip: text; /* 使用背景色作为文本颜色的剪裁区域 */
          color: transparent; /* 将文本颜色设为透明 */
        }
        .context {
          font-size: 24rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-left: 32rem;
          margin-top: 16rem;
          z-index: 999999;
          position: absolute;
        }
      }
      .annv {
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 28rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #2196f3;
        .parse {
          width: 630rem;
          height: 80rem;
          border-radius: 100rem;
          border: 1px solid #2196f3;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .training {
          width: 630rem;
          height: 80rem;
          border-radius: 100rem;
          background: #2196f3;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
</style>